import React from "react";
import { PureComponent } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

type ComparedWith = {
    id: number,
    changed: number,
    // unchanged: number,
    missing: number,
}

type Run = {
    id: number,
    metadata: Map<string, string>,
    timestamp: number,
    screenshot_count: number,
    compared_with: ComparedWith,
}

type ProjectProps = {
    projectId?: string,
    meta_filter?: string | null,
    filter?: string | null,
    refresh: number,
};
type ProjectState = {
    runs: Run[],
    filter?: string | null,
};


class ProjectInner extends PureComponent<ProjectProps, ProjectState> {
    state: ProjectState = {
        runs: [],
        filter: null,
    };

    componentDidMount() {
        this.setState({ filter: this.props.filter });
        fetch("/" + this.props.projectId + "/runs?filter=" + (this.props.filter || ""))
            .then(response => response.json())
            .then(data => {
                this.setState({ runs: data });
            });
    }

    componentDidUpdate(prevProps: ProjectProps) {
        if (this.props.refresh !== prevProps.refresh) {
            fetch("/" + this.props.projectId + "/runs?filter=" + (this.state.filter || ""))
                .then(response => response.json())
                .then(data => {
                    this.setState({ runs: data });
                });
        }
    }

    updateFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        /*eslint no-restricted-globals: ["off"]*/
        history.pushState({}, "", `?filter=${event.target.value}`);
        this.setState({ filter: event.target.value });
        fetch("/" + this.props.projectId + "/runs?filter=" + event.target.value)
            .then(response => response.json())
            .then(data => {
                this.setState({ runs: data });
            });
    }

    render() {
        return (
            <Container fluid="lg">
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <input type="text" placeholder="Filter metadata" onChange={this.updateFilter} value={this.state.filter || ""} />
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Timestamp</th>
                                    <th>Number of screenshots</th>
                                    <th>Meta</th>
                                    <th>Latest comparison</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.runs
                                        .filter((run) => {
                                            if (this.props.meta_filter) {
                                                let filter = JSON.parse(this.props.meta_filter);
                                                for (let [key, value] of Object.entries(filter)) {
                                                    let key_found = false;
                                                    for (let [rkey, rvalue] of Object.entries(run.metadata)) {
                                                        if (key === rkey) {
                                                            key_found = true;
                                                        }
                                                        if (key === rkey && value !== rvalue) {
                                                            return false;
                                                        }
                                                    }
                                                    if (!key_found) {
                                                        return false;
                                                    }
                                                }
                                            }
                                            return true;
                                        })
                                        .map((run, index) => {
                                            let metadata = Object.entries(run.metadata);
                                            metadata.sort();
                                            return <tr key={index}>
                                                <td>{run.id}</td>
                                                <td>{(new Date(run.timestamp * 1000)).toLocaleString()}</td>
                                                <td>{run.screenshot_count}</td>
                                                <td>{
                                                    metadata.map(([key, value]) => {
                                                        return <div key={key}>{key}: {value}</div>
                                                    })
                                                }</td>
                                                <td>
                                                    {
                                                        run.compared_with ?
                                                            <div style={{ display: "flex" }}>
                                                                <a href={`/project/${this.props.projectId}/run/${run.id}/compare/${run.compared_with.id}`}>{run.compared_with.id}</a>
                                                                {run.compared_with.missing > 0 ? <div style={{ display: "flex", marginLeft: "15px" }}><div style={{
                                                                    "height": "10px", "width": "10px",
                                                                    "backgroundColor": "red",
                                                                    "borderRadius": "50%",
                                                                    "display": "inline-block",
                                                                    margin: "8px"
                                                                }}></div><div>{run.compared_with.missing}</div></div> : <div></div>}
                                                                {run.compared_with.changed > 0 ? <div style={{ display: "flex", marginLeft: "15px" }}><div style={{
                                                                    "height": "10px", "width": "10px",
                                                                    "backgroundColor": "yellow",
                                                                    "borderRadius": "50%",
                                                                    "display": "inline-block",
                                                                    margin: "8px"
                                                                }}></div><div>{run.compared_with.changed}</div></div> : <div></div>}
                                                                {/* {run.compared_with.unchanged > 0 ? <div style={{ display: "flex", marginLeft: "15px" }}><div style={{
                                                                    "height": "10px", "width": "10px",
                                                                    "backgroundColor": "green",
                                                                    "borderRadius": "50%",
                                                                    "display": "inline-block",
                                                                    margin: "8px"
                                                                }}></div><div>{run.compared_with.unchanged}</div></div> : <div></div>} */}
                                                            </div>
                                                            : "view run"
                                                    }
                                                </td>
                                            </tr>
                                        })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container >
        );
    }
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Project(props: ProjectProps) {
    let params = useParams();
    let meta_filter = useQuery().get("meta");
    let filter = useQuery().get("filter");

    if (props.projectId === undefined) {
        return <ProjectInner key="project-list-inner" {...props} projectId={params.project_id} meta_filter={meta_filter} filter={filter} />;
    } else {
        return <ProjectInner key="project-list-inner" {...props} meta_filter={meta_filter} filter={filter} />;
    }
};
