import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

type LandingState = {
    refreshLeaderboard: number,
};
class Landing extends Component<{}, LandingState> {
    state: LandingState = {
        refreshLeaderboard: 0
    };
    render() {
        return (
            <>
                <Container fluid="lg">
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 7, offset: 2 }}>
                            <h1 className="display-5 text-center">
                                Pixel Eagle helps you test that your game remains pixel perfect!
                            </h1>
                        </Col>
                        <Col xs={2}>
                            <img
                                alt="logo"
                                src="/logo-1200.png"
                                width="130"
                                height="130"
                                className="d-inline-block align-top"
                            />
                        </Col>
                    </Row>
                    <Row><Col>&nbsp;</Col></Row>
                </Container>

            </>
        );
    }
}

export default Landing;
