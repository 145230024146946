import React from "react";
import { PureComponent } from "react";
import { Button, ButtonGroup, Col, Container, FormCheck, FormLabel, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";


type Run = {
    id: number,
    metadata: Map<string, string>,
    timestamp: number,
    screenshot_count: number,
    compared_with: number,
}

type Screenshot = {
    name: string,
    hash: string,
    previous_hash?: string,
    diff?: { Done: number }
}

type ComparisonProps = {
    projectId?: string,
    from?: number,
    to?: number,
    refresh: number,
    requestedScreenshot?: string | null,
};
type ComparisonState = {
    run: Run,
    missing: Screenshot[],
    new: Screenshot[],
    unchanged: Screenshot[],
    diff: Screenshot[],
    selected?: Screenshot,
    viewing: string,
};

class Preview extends PureComponent<{ selected: boolean, projectId: string, screenshot: Screenshot, onClick: () => void }> {
    render() {
        return <Container className={this.props.selected ? "alert alert-info" : "alert"}>
            <Row>
                <Col>
                    {this.props.screenshot.name}
                </Col>
                <Col md="auto">
                    {this.props.screenshot.diff?.Done ?
                        <div>
                            {this.props.screenshot.diff?.Done ? (this.props.screenshot.diff?.Done * 100.0).toFixed(2) + "%" : "not available"}
                        </div>
                        : <div />
                    }
                </Col>
            </Row>
            <div style={{ position: "relative" }} onClick={this.props.onClick}>
                <img width="100%" loading="lazy" src={`/${this.props.projectId}/screenshot/${this.props.screenshot.hash}`} alt="new" />
                {this.props.screenshot.previous_hash && this.props.screenshot.diff?.Done ?
                    <img width="100%" loading="lazy" style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "rgba(1.0, 1.0, 1.0, 0.5)",
                    }} src={`/${this.props.projectId}/diff/${this.props.screenshot.hash}/${this.props.screenshot.previous_hash}`} alt="diff" />
                    : <div />
                }
            </div>
        </Container>
    }
}



class Selector extends PureComponent<{ label: string, count: number, checked: boolean, onClick: () => void }> {

    render() {
        return <Row onClick={this.props.count > 0 ? this.props.onClick : undefined}>
            <Col md={{ span: 5 }}>
                <FormLabel>{this.props.label}</FormLabel>
            </Col>
            <Col style={{ textAlign: "right" }}>
                {this.props.count}
            </Col>
            <Col style={{ textAlign: "right" }}>
                <FormCheck type="radio" inline name="display-mode"
                    disabled={this.props.count === 0}
                    checked={this.props.checked}
                />
            </Col>
        </Row>

    }
}

class Viewer extends PureComponent<{ projectId: string, screenshot: Screenshot }, { display_new: boolean, display_overlay: boolean }> {
    state = {
        display_new: true,
        display_overlay: true,
    }

    componentDidUpdate(prevProps: { projectId: string, screenshot: Screenshot }) {
        if (this.props.screenshot !== prevProps.screenshot) {
            this.setState({ display_new: true });
        }
    }

    render() {
        return <Container>
            <Row>
                <Col md={{ span: 3 }}>
                    <ButtonGroup className="m-2">
                        <Button className="btn-sm"
                            disabled={this.props.screenshot.diff === undefined}
                            onClick={() => this.setState({ display_new: false, display_overlay: false })}
                            variant={this.state.display_new ? "secondary" : "success"}
                        >
                            Previous
                        </Button>
                        <Button className="btn-sm"
                            disabled={this.props.screenshot.diff === undefined}
                            onClick={() => this.setState({ display_new: !this.state.display_new, display_overlay: false })}
                            variant="info"
                        >
                            &lt;-&gt;
                        </Button>
                        <Button className="btn-sm"
                            disabled={this.props.screenshot.diff === undefined}
                            onClick={() => this.setState({ display_new: true, display_overlay: false })}
                            variant={this.state.display_new ? "success" : "secondary"}
                        >
                            New
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col>
                    <Button className="m-2 btn-sm"
                        disabled={this.props.screenshot.diff === undefined || this.props.screenshot.diff?.Done === undefined}
                        onClick={() => this.setState({ display_overlay: !this.state.display_overlay })}
                        variant={this.state.display_overlay ? "danger" : "secondary"}
                    >
                        Overlay
                    </Button>
                </Col>
            </Row>
            <div style={{ position: "relative" }}>
                {this.state.display_new ?
                    <img width="100%" loading="lazy" src={`/${this.props.projectId}/screenshot/${this.props.screenshot.hash}`} alt="new" />
                    : <img width="100%" loading="lazy" src={`/${this.props.projectId}/screenshot/${this.props.screenshot.previous_hash}`} alt="previous" />
                }
                {
                    this.props.screenshot.previous_hash !== undefined && this.props.screenshot.diff?.Done && this.state.display_overlay ?
                        <img width="100%" loading="lazy" style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "rgba(1.0, 1.0, 1.0, 0.7)",
                        }} src={`/${this.props.projectId}/diff/${this.props.screenshot.hash}/${this.props.screenshot.previous_hash}`} alt="diff" />
                        : <div />
                }
            </div>
        </Container >
    }
}
class ComparisonInner extends PureComponent<ComparisonProps, ComparisonState> {
    state: ComparisonState = {
        run: { id: 0, metadata: new Map<string, string>(), timestamp: 0, screenshot_count: 0, compared_with: 0 },
        missing: [],
        new: [],
        unchanged: [],
        diff: [],
        selected: undefined,
        viewing: "Changed",

    };

    selectScreenshot(screenshot: Screenshot) {
        this.setState({ selected: screenshot });
        window.history.pushState({}, "", "/project/" + this.props.projectId + "/run/" + this.props.from + "/compare/" + this.props.to + "?screenshot=" + screenshot.name);
    }

    componentDidMount() {
        fetch("/" + this.props.projectId + "/runs/" + this.props.from + "/compare/" + this.props.to)
            .then(response => response.json())
            .then(data => {
                this.setState({ missing: data.missing, new: data.new, unchanged: data.unchanged, diff: data.diff });
                if (this.props.requestedScreenshot !== null) {
                    let screenshot;
                    for (let item of data.diff) {
                        if (item.name === this.props.requestedScreenshot) {
                            this.setState({ viewing: "Changed" });
                            screenshot = item;
                        }
                    }
                    for (let item of data.new) {
                        if (item.name === this.props.requestedScreenshot) {
                            this.setState({ viewing: "New" });
                            screenshot = item;
                        }
                    }
                    for (let item of data.missing) {
                        if (item.name === this.props.requestedScreenshot) {
                            this.setState({ viewing: "Missing" });
                            screenshot = item;
                        }
                    }
                    for (let item of data.unchanged) {
                        if (item.name === this.props.requestedScreenshot) {
                            this.setState({ viewing: "Unchanged" });
                            screenshot = item;
                        }
                    }
                    if (screenshot !== undefined) {
                        this.setState({ selected: screenshot });
                    }
                }
            });
        fetch("/" + this.props.projectId + "/runs/" + this.props.from)
            .then(response => response.json())
            .then(data => {
                this.setState({ run: data });
            });
    }

    componentDidUpdate(prevProps: ComparisonProps) {
        if (this.props.refresh !== prevProps.refresh) {
            fetch("/" + this.props.projectId + "/runs/" + this.props.from + "/compare/" + this.props.to)
                .then(response => response.json())
                .then(data => {
                    this.setState({ missing: data.missing, new: data.new, unchanged: data.unchanged, diff: data.diff });
                    if (this.props.requestedScreenshot !== null) {
                        let screenshot;
                        for (let item of data.diff) {
                            if (item.name === this.props.requestedScreenshot) {
                                this.setState({ viewing: "Changed" });
                                screenshot = item;
                            }
                        }
                        for (let item of data.new) {
                            if (item.name === this.props.requestedScreenshot) {
                                this.setState({ viewing: "New" });
                                screenshot = item;
                            }
                        }
                        for (let item of data.missing) {
                            if (item.name === this.props.requestedScreenshot) {
                                this.setState({ viewing: "Missing" });
                                screenshot = item;
                            }
                        }
                        for (let item of data.unchanged) {
                            if (item.name === this.props.requestedScreenshot) {
                                this.setState({ viewing: "Unchanged" });
                                screenshot = item;
                            }
                        }
                        if (screenshot !== undefined) {
                            this.setState({ selected: screenshot });
                        }
                    }
                });
            fetch("/" + this.props.projectId + "/runs/" + this.props.from)
                .then(response => response.json())
                .then(data => {
                    this.setState({ run: data });
                });
        }
    }

    render() {
        let metadata = Object.entries(this.state.run?.metadata);
        metadata.sort();

        return (
            <Container fluid>
                <Row>
                    <Col md={{ span: 3 }} className="overflow-y-scroll" style={{ height: "90vh" }}>
                        <Container style={{ fontSize: "0.8rem" }}>
                            <Row>
                                <Col>
                                    <Container>
                                        <Row>
                                            <Col md={{ span: 1 }}>
                                                <a href={`/project/${this.props.projectId}`}>
                                                    <Button className="btn-sm btn-info">
                                                        &lt;
                                                    </Button>
                                                </a>
                                            </Col>
                                            <Col style={{ fontSize: "1.1rem", textAlign: "right" }}>
                                                {(new Date(this.state.run.timestamp * 1000)).toLocaleString()}
                                            </Col>
                                        </Row>
                                        {
                                            metadata.map(([key, value]) => {
                                                return <Row key={key}>
                                                    <Col md={{ span: 3 }}>{key}</Col>
                                                    <Col style={{ textAlign: "right", overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</Col>
                                                </Row>
                                            })
                                        }
                                    </Container>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <Container>
                                        <Selector label="Missing" count={this.state.missing.length} checked={this.state.viewing === "Missing"} onClick={() => this.setState({ viewing: "Missing" })} />
                                        <Selector label="New" count={this.state.new.length} checked={this.state.viewing === "New"} onClick={() => this.setState({ viewing: "New" })} />
                                        <Selector label="Changed" count={this.state.diff.length} checked={this.state.viewing === "Changed"} onClick={() => this.setState({ viewing: "Changed" })} />
                                        <Selector label="Unchanged" count={this.state.unchanged.length} checked={this.state.viewing === "Unchanged"} onClick={() => this.setState({ viewing: "Unchanged" })} />
                                    </Container>
                                </Col>
                            </Row>
                            {/* </Container>
                    </Col>
                    <Col md={{ span: 2 }}>
                        <Container style={{ fontSize: "0.8rem" }}> */}
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            {this.state.viewing === "Missing" ?
                                <Row>
                                    {this.state.missing.map((screenshot, index) => {
                                        return <Preview key={screenshot.hash} selected={this.state.selected === screenshot} projectId={this.props.projectId!} screenshot={screenshot} onClick={() => this.selectScreenshot(screenshot)} />
                                    })}
                                </Row>
                                : <div />
                            }
                            {this.state.viewing === "New" ?
                                <Row>
                                    {this.state.new.map((screenshot, index) => {
                                        return <Preview key={screenshot.hash} selected={this.state.selected === screenshot} projectId={this.props.projectId!} screenshot={screenshot} onClick={() => this.selectScreenshot(screenshot)} />
                                    })}
                                </Row>
                                : <div />
                            }
                            {this.state.viewing === "Changed" ?
                                <Row>
                                    {this.state.diff.map((screenshot, index) => {
                                        return <Preview key={screenshot.hash} selected={this.state.selected === screenshot} projectId={this.props.projectId!} screenshot={screenshot} onClick={() => this.selectScreenshot(screenshot)} />
                                    })}
                                </Row>
                                : <div />
                            }
                            {this.state.viewing === "Unchanged" ?
                                <Row>
                                    {this.state.unchanged.map((screenshot, index) => {
                                        return <div key={screenshot.hash}
                                            onClick={() => this.selectScreenshot(screenshot)}
                                            className={this.state.selected === screenshot ? "alert alert-info" : "alert"}
                                        >
                                            {screenshot.name}
                                        </div>
                                    })}
                                </Row>
                                : <div />
                            }
                        </Container >
                    </Col>
                    <Col md={{ span: 9 }}>
                        {this.state.selected ?
                            <Viewer projectId={this.props.projectId!} screenshot={this.state.selected!} />
                            : <div>select a screenshot on the left</div>
                        }
                    </Col>
                </Row>
            </Container >
        );
    }
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Comparison(props: ComparisonProps) {
    let params = useParams();
    let requested_screenshot = useQuery().get("screenshot");
    if (props.projectId === undefined) {
        return <ComparisonInner {...props} projectId={params.project_id} from={Number(params.from)} to={Number(params.to)} requestedScreenshot={requested_screenshot} />;
    } else {
        return <ComparisonInner {...props} requestedScreenshot={requested_screenshot} />;
    }
};
